<template>
  <el-popover @show="handleNote" placement="top-end" width="400" trigger="click">
    <el-form @submit="onSubmit">
      <el-form-item>
        <el-input
          v-model="note"
          maxlength="255"
          show-word-limit
          type="textarea"
          :rows="3"
          placeholder="Add note..."
        ></el-input>
      </el-form-item>
      <div style="text-align: right">
        <div style="color: #f56c6c; margin-bottom: 0.5rem" v-if="invalidId">
          <span>Failed, try to refresh page.</span>
        </div>
        <div v-if="!confirmation">
          <el-button :disabled="invalidId" @click="confirmation = true" size="mini" round plain
            >Save</el-button
          >
        </div>
        <div v-else>
          <span style="margin-right: 0.5rem">Are you sure?</span>
          <el-button :disabled="invalidId" @click="onSubmit" size="mini" round type="primary"
            >Yes</el-button
          >
          <el-button :disabled="invalidId" @click="confirmation = false" size="mini" round plain
            >No</el-button
          >
        </div>
      </div>
    </el-form>
    <div slot="reference">
      <span v-if="!scope?.row?.note" style="cursor: pointer; color: #909399; margin-right: 0.25rem"
        >N/A</span
      >
      <span v-else style="cursor: pointer; margin-right: 0.25rem">{{ scope?.row?.note }}</span>
      <el-button type="text" icon="el-icon-edit" size="mini" circle plain></el-button>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: ["scope"],
  data() {
    return {
      note: "",
      fallbackNote: "", // to handle failed request
      confirmation: false,
      invalidId: false,
    };
  },
  methods: {
    handleNote() {
      this.note = this.scope?.row?.note || "";
    },
    onSubmit(e) {
      e.preventDefault();
      this.confirmation = false;
      // handling if row data does not id information, nothing can be requested
      if (this.scope?.row?._id === undefined || this.note.includes("/note-noid/")) {
        this.invalidId = true;
        return;
      }

      // update immediately for experience purpose
      this.fallbackNote = this.scope.row.note;
      this.scope.row.note = this.note;

      this.$store
        .dispatch("WHATSAPP_ADD_NOTE", {
          id: this.scope.row._id,
          text: this.note,
        })
        .catch(() => {
          // revert to fallback if request failed
          this.$message.error(`Error adding note, try again later. ID: ${this.scope.row._id}`);
          this.scope.row.note = this.fallbackNote;
        })
        .finally(() => {
          this.fallbackNote = "";
          this.$forceUpdate();
        });
    },
  },
};
</script>
